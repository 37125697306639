export const statusLabel = (tag: string) => {
    return statusTagMenuItem?.find((item: any) => item.key === tag)?.label
}
export const idleTimeMsg = 'Your session has expired due to inactivity'
export const inactivityMsgPrimary =
    'Your session has timed out due to inactivity!'
export const inactivityMsgSecondary = 'Please enter your password for'
export const sessionmsg = 'Session expired!'
export const anotherSession = 'Your session has expired due to another login'

export const statusButtons = [
    {
        id: 1,
        label: 'Scheduled',
        key: 'Scheduled',
        divPrimary: 'Scheduled',
        typography: 'typoRobotoReceived',
    },
    {
        id: 2,
        label: 'Ready For Audit',
        key: 'Ready_for_Audit',
        divPrimary: 'ReadyForAudit',
        typography: 'typoRobotoReadyForAudit',
    },
    {
        id: 3,
        label: 'Audit In Progress',
        key: 'Audit_in_Progress',
        divPrimary: 'AuditInProgress',
        typography: 'typoRobotoAuditInProgress',
    },
    {
        id: 4,
        label: 'Completed',
        key: 'Completed',
        divPrimary: 'Completed',
        typography: 'typoRobotoCompleted',
    },
    {
        id: 5,
        label: 'Validated Record',
        key: 'Validated_Record',
        divPrimary: 'ValidatedRecord',
        typography: 'typoRobotoValidated',
    },
    {
        id: 6,
        label: 'Validated Coding',
        key: 'Validated_Coding',
        divPrimary: 'ValidatedCoding',
        typography: 'typoRobotoValidated',
    },
    {
        id: 7,
        label: 'Validated Record & Coding',
        key: 'Validated_Record_Coding',
        divPrimary: 'ValidatedRecordCoding',
        typography: 'typoRobotoValidated',
    },
    {
        id: 8,
        label: 'Audit Processing Escalated',
        key: 'Audit_Escalated',
        divPrimary: 'AuditProcessingEscalated',
        typography: 'typoRobotoAuditEscalated',
    },
    {
        id: 10,
        label: 'Sent To Interface',
        key: 'Sent_to_Interface',
        divPrimary: 'SentToInterface',
        typography: 'typoRobotoSentToInterface',
    },
]
export const statusTagMenuItem = [
    {
        id: 1,
        label: 'Scheduled',
        key: 'Scheduled',
        divPrimary: 'Scheduled',
        typography: 'typoRobotoReceived',
        // divsubSecondary: '#D9F5FD',
        // divsScondary: 'Scheduled',
        // color: '#0059FF',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },
    {
        id: 2,
        label: 'Processing',
        key: 'Processing',
        divPrimary: 'Processing',
        typography: 'typoRobotoProcessing',
        // divsubSecondary: '#D9F5FD',
        // divsScondary: 'todo',
        // color: '#0059FF',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },
    {
        id: 3,
        label: 'Received',
        key: 'Received',
        divPrimary: 'Received',
        typography: 'typoRobotoReceived',
        // divsubSecondary: '#D9F5FD',
        // divsScondary: 'todo',
        // color: '#0059FF',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },

    {
        id: 4,
        label: 'Unable to Process',
        key: 'Unable_to_Process',
        divPrimary: 'UnableToProcess',
        // divsScondary: 'UnableToProcess',
        // divsubSecondary: '#FEEFCB',
        typography: 'typoRobotoUnableToProcess',
        // color: '#FFA600',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },
    {
        id: 5,
        label: 'Ready For Audit',
        key: 'Ready_for_Audit',
        divPrimary: 'ReadyForAudit',
        // divsScondary: 'received',
        // divsubSecondary: '#D9BAF4',
        typography: 'typoRobotoReadyForAudit',
        // color: '#58508D',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },
    {
        id: 6,
        label: 'Audit In Progress',
        key: 'Audit_in_Progress',
        divPrimary: 'AuditInProgress',
        // divsScondary: 'notreceived',
        // divsubSecondary: '#FFECEE',
        typography: 'typoRobotoAuditInProgress',
        // color: '#F83026',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },
    {
        id: 7,
        label: 'Completed',
        key: 'Completed',
        divPrimary: 'Completed',
        // divsScondary: 'transcribed',
        // divsubSecondary: '#84B9FD',
        typography: 'typoRobotoCompleted',
        // color: '#84B9FD',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },
    {
        id: 8,
        label: 'Addendum Received',
        key: 'Addendum_Received',
        divPrimary: 'AddendumReceived',
        // divsScondary: 'review',
        // divsubSecondary: '#FCD6BB',
        typography: 'typoRobotoAddendumReceived',
        color: '#FF6700',
        roles: ['auditor'],
    },
    {
        id: 9,
        label: 'Addendum Ready For Audit',
        key: 'Addendum_Ready_for_Audit',
        divPrimary: 'AddendumReadyForAudit',
        // divsScondary: 'claimsubmitted',
        // divsubSecondary: '#E9F5CE',
        typography: 'typoRobotoAddendumReady',
        color: '#59A531',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },
    {
        id: 10,
        label: 'Addendum In Progress',
        key: 'Addendum_in_Progress',
        divPrimary: 'AddendumInProgress',
        // divsScondary: 'claimsubmitted',
        // divsubSecondary: '#E9F5CE',
        typography: 'typoRobotoAddendumInProgress',
        // color: '#59A531',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },
    {
        id: 11,
        label: 'Addendum Merged',
        key: 'Addendum_Merged',
        divPrimary: 'AddendumMerged',
        typography: 'typoRobotoAddendumMerged',
        // divsubSecondary: '#C454A3',
        // divsubSecondary: '#E9F5CE',
        // divsScondary: 'Edited2A',
        // color: '#C454A3',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },
    {
        id: 12,
        label: 'Addendum Completed',
        key: 'Addendum_Completed',
        divPrimary: 'AddendumCompleted',
        typography: 'typoRobotoAddendumCompleted',
        // divsubSecondary: '#C454A3',
        // divsubSecondary: '#E9F5CE',
        // divsScondary: 'Edited2A',
        // color: '#C454A3',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },
    {
        id: 13,
        label: 'Validated Record',
        key: 'Validated_Record',
        divPrimary: 'ValidatedRecord',
        typography: 'typoRobotoValidated',
        // divsubSecondary: '#C454A3',
        // divsubSecondary: '#E9F5CE',
        // divsScondary: 'Edited2A',
        // color: '#C454A3',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },
    {
        id: 14,
        label: 'Validated Coding',
        key: 'Validated_Coding',
        divPrimary: 'ValidatedCoding',
        typography: 'typoRobotoValidated',
        // divsubSecondary: '#C454A3',
        // divsubSecondary: '#E9F5CE',
        // divsScondary: 'Edited2A',
        // color: '#C454A3',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },
    {
        id: 15,
        label: 'Validated Record & Coding',
        key: 'Validated_Record_Coding',
        divPrimary: 'ValidatedRecordCoding',
        typography: 'typoRobotoValidated',
        // divsubSecondary: '#C454A3',
        // divsubSecondary: '#E9F5CE',
        // divsScondary: 'Edited2A',
        // color: '#C454A3',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },
    {
        id: 16,
        label: 'Audit Suspended',
        key: 'Audit_Suspended',
        divPrimary: 'AuditSuspended',
        typography: 'typoRobotoAuditSuspended',
        // divsubSecondary: '#C454A3',
        // divsubSecondary: '#E9F5CE',
        // divsScondary: 'Edited2A',
        // color: '#C454A3',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },
    {
        id: 17,
        label: 'Audit Overdue',
        key: 'Audit_Overdue',
        divPrimary: 'AuditOverdue',
        typography: 'typoRobotoAuditOverdue',
        // divsubSecondary: '#C454A3',
        // divsubSecondary: '#E9F5CE',
        // divsScondary: 'Edited2A',
        // color: '#C454A3',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },
    {
        id: 18,
        label: 'Audit Processing Escalated',
        key: 'Audit_Escalated',
        divPrimary: 'AuditProcessingEscalated',
        typography: 'typoRobotoAuditEscalated',
        // divsubSecondary: '#C454A3',
        // divsubSecondary: '#E9F5CE',
        // divsScondary: 'Edited2A',
        // color: '#C454A3',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },
    {
        id: 19,
        label: 'Sent To Interface',
        key: 'Sent_to_Interface',
        divPrimary: 'SentToInterface',
        typography: 'typoRobotoSentToInterface',
        // divsubSecondary: '#C454A3',
        // divsubSecondary: '#E9F5CE',
        // divsScondary: 'Edited2A',
        // color: '#C454A3',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },
    {
        id: 20,
        label: 'No Process Required',
        key: 'No_Process_Required',
        divPrimary: 'NoProcessRequired',
        typography: 'typoRobotoNoProcessRequired',
        // divsubSecondary: '#C454A3',
        // divsubSecondary: '#E9F5CE',
        // divsScondary: 'Edited2A',
        // color: '#C454A3',
        roles: ['auditoradmin', 'auditormanager'],
    },
]

export const options = [
    {
        id: 1,
        key: 0,
        label: 'View Task',
        roles: ['auditoradmin', 'auditormanager', 'auditor'],
    },
    {
        id: 2,
        key: 1,
        label: 'Edit Task',
        roles: ['auditoradmin', 'auditormanager'],
    },
    {
        id: 3,
        key: 2,
        label: 'Reassign Task',
        roles: ['auditoradmin', 'auditormanager'],
    },
    {
        id: 4,
        key: 3,
        label: 'Change Status',
        roles: ['auditoradmin', 'auditormanager'],
    },
    {
        id: 5,
        key: 6,
        label: 'Mark Claim Submitted',
        roles: ['auditoradmin', 'auditormanager'],
    },
]
export const settingsOptions = [
    {
        id: 1,
        key: 'status',
        readonly: true,
        fil: true,
        label: 'Status',
        roles: ['auditoradmin', 'auditormanager', 'auditor'],
    },
    {
        id: 2,
        key: 'location',
        readonly: false,
        fil: true,
        label: 'Contract Client',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },
    {
        id: 3,
        key: 'city',
        readonly: false,
        fil: true,
        label: 'City, State',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },

    {
        id: 4,
        key: 'auditorName',
        readonly: false,
        fil: true,
        label: 'Auditor Name',
        roles: ['auditoradmin', 'auditormanager'],
    },
    {
        id: 5,
        key: 'providerName',
        readonly: false,
        fil: true,
        label: 'Provider Name',
        roles: ['auditoradmin', 'auditormanager', 'auditor'],
    },
    {
        id: 6,
        key: 'patientName',
        readonly: false,
        fil: true,
        label: 'Patient Name',
        roles: ['auditoradmin', 'auditormanager', 'auditor'],
    },
    {
        id: 7,
        key: 'site',
        readonly: false,
        fil: true,
        label: 'Site of Service',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },
    {
        id: 8,
        key: 'startDate',
        readonly: false,
        fil: false,
        label: 'Encounter Date',
        roles: ['auditoradmin', 'auditormanager', 'auditor'],
    },
    {
        id: 9,
        key: 'taskName',
        readonly: false,
        fil: true,
        label: 'Reason for Visit',
        roles: ['auditoradmin', 'auditormanager', 'auditor'],
    },
    {
        id: 7,
        key: 'speciality',
        readonly: false,
        fil: true,
        label: 'Speciality',
        roles: ['auditoradmin', 'auditormanager', 'auditor'],
    },
    {
        id: 8,
        key: 'audioReceived',
        readonly: false,
        fil: true,
        label: 'Audio Received / Length',
        roles: ['auditoradmin', 'auditormanager', 'auditor'],
    },
    {
        id: 9,
        key: 'TATtime',
        readonly: false,
        fil: false,
        label: 'TAT Time',
        roles: ['auditor', 'auditoradmin', 'auditormanager'],
    },

    {
        id: 12,
        key: 'patientAccount',
        readonly: false,
        fil: true,
        label: 'Patient Account',
        roles: ['auditoradmin', 'auditormanager'],
    },
    {
        id: 10,
        key: 'audio',
        readonly: true,
        label: 'Audio',
        fil: false,
        roles: ['auditoradmin', 'auditormanager', 'auditor'],
    },
    {
        id: 11,
        key: 'encounterCollection',
        readonly: false,
        fil: false,
        label: 'Encounter Collection',
        roles: ['auditoradmin', 'auditormanager'],
    },

    {
        id: 15,
        key: 'appointmentType',
        readonly: false,
        fil: true,
        label: 'Appointment Type',
        roles: ['auditoradmin', 'auditormanager'],
    },

    {
        id: 17,
        key: 'transcript',
        readonly: false,
        label: 'Transcripts',
        fil: false,
        roles: ['auditoradmin', 'auditormanager', 'auditor'],
    },

    // {
    //     id: 20,
    //     key: 'coding',
    //     readonly: true,
    //     label: 'Coding Form',
    //     fil: false,
    //     roles: ['auditoradmin', 'auditormanager', 'auditor'],
    // },
]
export const NotificationsData = [
    {
        id: 1,
        com: "Audio is added to the 'Task Reviewing policies and procedures'",
        date: 'August 23,2022',
        time: '9:25am',
        list: 'play audio',
    },
    {
        id: 2,
        com: "Task 'Reviewing policies and procedures' status changed from In Progress to To do",
        date: 'August 23,2022',
        time: '9:25am',
        list: 'view task',
        name: 'Matt Wingate',
    },
    {
        id: 3,
        com: "Sent you a message on Task 'Reviewing policies and procedures'",
        date: 'August 23, 2022',
        time: '9:25am',
        list: 'view chat',
        name: 'Matt Wingate',
    },
    {
        id: 3,
        com: "Task 'Reviewing policies and procedures' has been marked as Completed",
        date: 'August 23, 2022',
        time: '9:25am',
        list: 'view task',
        name: 'Matt Wingate',
    },
    {
        id: 4,
        com: "Task 'Reviewing policies and procedures' has been marked as Completed",
        date: 'August 23, 2022',
        time: '9:25am',
        list: 'view task',
        name: 'Matt Wingate',
    },
    {
        id: 5,
        com: "Task 'Reviewing policies and procedures' has been marked as Completed",
        date: 'August 23, 2022',
        time: '9:25am',
        list: 'view task',
        name: 'Matt Wingate',
    },
    {
        id: 6,
        com: "Task 'Reviewing policies and procedures' has been marked as Completed",
        date: 'August 23, 2022',
        time: '9:25am',
        list: 'view task',
        name: 'Matt Wingate',
    },
    {
        id: 7,
        com: "Task 'Reviewing policies and procedures' has been marked as Completed",
        date: 'August 23, 2022',
        time: '9:25am',
        list: 'view task',
        name: 'Matt Wingate',
    },
]

export function stringToColor(string: string) {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xcf
        color += `00${value.toString(16)}`.slice(-2)
    }
    /* eslint-enable no-bitwise */

    return color
}

export function stringToborColor(string: string) {
    let hash = 0
    let i
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }
    let color = '#'
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff
        color += `00${value.toString(16)}`.slice(-2)
    }
    return color
}

export const findLength = (strings: any) => {
    const total = strings?.length
    const names = strings?.map((item: any) => {
        return item.name
    })
    const vlength = names.toString().substring(0, 21)
    const tt = total - vlength.split(',').length
    return tt > 0
        ? `${names.join(', ').substring(0, 21)}...+${
              +total - vlength.split(',').length
          }`
        : `${names.join(', ').substring(0, 21)}...`
}
export const findThreaLength = (strings: any) => {
    const total = strings?.length
    const names = strings?.map((item: any) => {
        return item.name
    })
    const vlength = names.toString().substring(0, 31)
    const tt = total - vlength.split(',').length
    return tt > 0
        ? `${names.join(', ').substring(0, 31)}...+${
              +total - vlength.split(',').length
          }`
        : `${names.join(', ').substring(0, 31)}...`
}
export const roleName = (userRole: string) => {
    if (userRole === 'auditormanager') {
        return 'Auditor Manager'
    } else if (userRole === 'provider') {
        return 'Provider'
    }
    return userRole === 'auditor' ? 'Auditor' : 'Admin'
}
export const urlParse = (strings: string) => {
    const urlSplit = strings.split('/')
    const sliceurl = urlSplit.slice(3)
    return sliceurl
        .map((item, index) =>
            index === sliceurl.length - 1 ? item : item + '/',
        )
        .join('')
}

export function getTrancriptFilePath(transcript: any) {
    switch (transcript.seleced) {
        case 'A':
            return transcript.filePathPDF_2A
        case 'B':
            return transcript.filePathPDF_2B
        case 'C':
            return transcript.filePathPDF_2C
        case 'D':
            return transcript.filePathPdf_ChartNote
        default:
            return transcript.filePathPdf_D3
    }
}
export const singleFileErrMsg = 'Only one file to be upload.'
export const fileTypeErrMsg = 'File format not supported!'
export const fileSizeErrMsg =
    'File size exceeds! Only upto 16MB allowed to upload.'
export const ImgfileTypeErrMsg =
    'Invalid file type! Allowed file types ( jpeg, jpg, png )'
export const cancelErrMsg = 'Upload Cancelled'

export const Roleoptions = [
    'provider',
    'auditor',
    'auditormanager',
    'auditoradmin',
]
