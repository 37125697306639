import React from 'react'
import createPlotlyComponent from 'react-plotly.js/factory'
import * as Plotly from 'plotly.js-dist-min'
import styles from './TATMeter.module.css'

interface TATMeterProps {
    TATData?: any
}
function TATMeterPlot(props: TATMeterProps) {
    const Plot = createPlotlyComponent(Plotly)
    const getBarColor = (val: number) => {
        if (val < 30) return 'green' // Low range
        if (val < 70) return 'orange' // Medium range
        return '#ab0000' // High range
    }
    const data = [
        {
            domain: { x: [0, 1], y: [0, 1] },
            value: props.TATData,
            title: { text: 'TAT-Meter (min)' },
            type: 'indicator',
            mode: 'gauge+number',
            // delta: { reference: 20 },
            gauge: {
                bar: { color: getBarColor(props.TATData) },
                axis: {
                    range: [0, 180],
                    tickmode: 'array',
                    tickvals: [0, 20, 40, 60, 80, 100, 120, 140, 160, 180],
                    ticktext: [0, 20, 40, 60, 80, 100, 120, 140, 160, 180],
                },
                steps: [
                    { range: [0, 60], color: 'lightgray' },
                    { range: [60, 120], color: 'gray' },
                    { range: [120, 180], color: '#ff603d' },
                ],
                threshold: {
                    line: { color: 'red', width: 4 },
                    thickness: 0.75,
                    value: 100,
                },
            },
        },
    ]

    const layout = {
        // margin: { t: 60, b: 20 },
        margin: { t: 50, b: 10, l: 20, r: 10 },
        // width: 350,
        // height: 100,
    }
    // Plotly.newPlot('myDiv', data, layout);

    return (
        <Plot
            className={styles.TATMeterContainer}
            data={data}
            layout={layout}
            config={{ responsive: true }}
        />
    )
}

export default TATMeterPlot
