import React, { useState } from 'react'
import { LoaderGFContext } from '../../Components/Loader/LoaderProvider'
import { SnackbarContext } from '../../Components/Snackbar/SnackbarProvider'
import { getSettings } from '../api/settingAPIService'
import {
    getAuditors,
    getTask,
    getTasklist,
    taskSearch,
    taskSearchbyField,
} from '../api/taskAPIService'
import { paginationParams, paramsObj } from '../paginationService'
import { taskReduce } from '../reducer/taskReducer'
import { settingsOptions } from '../utils/constant'
import _ from 'lodash'
import { barchartAPI, bubblechartAPI } from '../api/chartAPIService'
import { useDispatch, useSelector } from 'react-redux'
import { SocketContext } from '../../Components/Sockets/Socket'
import { file_preview_open } from '../actions/authMonitorAction'
import {
    audioModal_action,
    audioPlayer_action,
} from '../actions/audioPlayerAction'
import { useTableService } from './tableService'
import { getEncounterData } from '../api/auditorAPIService'
import moment from 'moment'

interface SortingObj {
    [key: string]: number
}

const filterObj = {
    showStatus: false,
    showAuditor: false,
    status: [],
    auditor: [],
}

export function useTaskmanager() {
    type AuditorType = {
        message: string
        status: boolean
        statusCode: number
        users: Array<AuditorsUsersType>
    }
    type AuditorsUsersType = {
        email: string
        firstName: string
        id: string
        lastName: string
        profile: any
        userName: string
    }
    const { snackbarSetting } = React.useContext(SnackbarContext)
    const { loaderSetting } = React.useContext(LoaderGFContext)
    const { selectField } = useTableService()

    const userId = useSelector(
        (state: any) => state.persistReducers?.userReducer?.user?.id,
    )
    const userRole = useSelector(
        (state: any) => state.persistReducers?.userReducer?.user?.userRole,
    )
    const [encounterData, setEncounterData] = React.useState<any>()
    const [addendumEncounterData, setAddendumEncounterData] =
        React.useState<any>()
    const [taskList, setTaskList] = React.useReducer(taskReduce, [])
    const [name, setName] = React.useState('')
    const [filterdHeader, setFilterdHeader] = React.useState(
        settingsOptions[0].label,
    )
    const [filteredValue, setFilterdValue] = React.useState('')
    const [auditors, setAuditors] = React.useState<Array<AuditorsUsersType>>([])
    const [pagination, setPagination] = React.useState(paginationParams)
    const [filterFlag, setFilterFlag] = React.useState<any>(filterObj)
    const [openFilter, setopenFilter] = React.useState(true)
    const [enableModel, setEnableModel] = React.useState<any>(null)
    const [task, setTask] = React.useState<any>(null)
    const [aTask, setATask] = React.useState<any>(null)
    const [barchartData, setBarchartData] = React.useState<Array<any>>([])
    const [barchartDataCount, setBarchartDataCount] = React.useState<number>(0)
    const [bubblechartData, setbubblechartData] = React.useState<Array<any>>([])
    const [modalVisible, setModalVisible] = React.useState<any>(null)
    const [transcriptmodal, setTranscriptVisible] = React.useState<any>(null)
    const [fillablePdfmodal, setFillablePdfmodal] = React.useState<any>(null)
    const [document3PdfModel, setDocument3PdfModel] = React.useState<any>(null)
    const [sortingObj, setSortingObj] = React.useState<any>({ createdAt: -1 })
    const [addendumIndex, setAddendumIndex] = React.useState<any>(null)
    const [statusCount, setStatusCount] = React.useState<any>()
    const [loaderBool, setLoaderBool] = React.useState<boolean>(false)
    const [filterButton, setFilterButton] = React.useState<string>('')
    const [refresh, setRefresh] = React.useState<boolean>(false)
    const [averageTat, setAverageTat] = useState<number>()

    const dispatch = useDispatch()
    const productionDatetmp = useSelector(
        (state: any) => state.nonPersistReducers?.productionDateReducer.day,
    )
    const prodEndDatetmp = useSelector(
        (state: any) => state.nonPersistReducers?.productionDateReducer.endDay,
    )
    const productionDate = new Date(productionDatetmp)
    const prodEndDate = new Date(prodEndDatetmp)
    // const prodEndDate: Date = new Date(productionDate)
    // prodEndDate.setHours(23, 59, 59, 59)
    const { socket } = React.useContext(SocketContext)

    const gatherEncounterData = async (
        encounterId: any,
        addendumEncounterId?: any,
    ) => {
        try {
            const resp = await getEncounterData({ encounterId: encounterId })
            setLoaderBool(true)
            setEncounterData(resp.encounterData)
            // console.log('resp', resp)
            if (addendumEncounterId) {
                const resp2 = await getEncounterData({
                    encounterId: addendumEncounterId,
                })
                setAddendumEncounterData(resp2.encounterData)
            }
            setLoaderBool(false)
        } catch (err) {
            console.log('encounter data error', err)
        }
    }

    // React.useEffect(() => {
    //     async function initiate() {
    //         if (userRole && userRole !== 'auditor') {
    //             await barchart()
    //             await bubbleChart()
    //         }
    //     }
    //     initiate()
    // }, [])

    const barchart = async () => {
        const barcharResult = await barchartAPI()
        if (barcharResult?.status) {
            setBarchartData(barcharResult?.data)
            setBarchartDataCount(barcharResult?.totalCount)
        }
    }
    const bubbleChart = async () => {
        const bubblechartResult = await bubblechartAPI()
        if (bubblechartResult?.status) {
            setbubblechartData(bubblechartResult?.tasks)
        }
    }
    React.useEffect(() => {
        async function initiate() {
            await settingTaskmanager()
            await auditorList()
        }
        initiate()
    }, [])
    const receiverSocket = async (data: any) => {
        const message: any = data.message
        if (pagination.currentPage === 1) {
            const dataList = await getTasklist(
                pagination.count,
                pagination.currentPage,
                sortingObj,
                productionDate,
                prodEndDate,
            )
            resTask(dataList)
            setAverageTat(dataList.averageTat / 1000 / 60)
            setStatusCount(dataList?.statusCounts)
        } else if (taskList.find((item: any) => item.id === message.taskId)) {
            const getTaskData: any = await getTask(message.taskId)
            setTaskList({ payload: getTaskData?.task, type: 'update' })
        }
    }
    React.useEffect(() => {
        socket?.on('taskupdate', receiverSocket)
    }, [socket])

    React.useEffect(() => {
        async function initiate() {
            await taskListAPICall(name, pagination.currentPage)
        }
        initiate()
    }, [
        refresh,
        filterButton,
        productionDatetmp,
        prodEndDatetmp,
        pagination.count,
        pagination.currentPage,
        filterFlag.status,
        filterFlag.auditor,
        sortingObj,
    ])
    const settingTaskmanager = async () => {
        if (userId) {
            const result = await getSettings()
            const data: any = []
            for (const key in result) {
                const obj = settingsOptions.find(
                    (item: any) => item.key === key,
                )
                if (obj) {
                    obj.id = result[key]
                    data.push(obj)
                }
            }
            if (data?.length >= 1) {
                setFilterdHeader(data.find((item: any) => item.id === 1)?.label)
            }
        }
    }
    const filterSearchByField = async () => {
        setopenFilter(!openFilter)
        setFilterdValue('')
        setName('')
        settingTaskmanager()
        const result = await getTasklist(
            pagination.count,
            1,
            sortingObj,
            productionDate,
            prodEndDate,
        )
        setAverageTat(result.averageTat / 1000 / 60)

        resTask(result)
    }
    const filterMenuChange = async (data: any) => {
        setFilterdHeader(data)
        setFilterdValue('')
        loaderSetting(true)
        const result = await getTasklist(
            pagination.count,
            1,
            sortingObj,
            productionDate,
            prodEndDate,
        )
        setAverageTat(result.averageTat / 1000 / 60)

        resTask(result)
    }
    const filterOnChange = async (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
        setFilterdValue(e.target.value)
        paramsObj.pageSize = pagination.count
        paramsObj.pageNumber = 1
        if (e.target.value !== '' && e.target.value.length > 0) {
            paramsObj.searchKey = settingsOptions?.find(
                (item: any) => item.label === filterdHeader,
            )?.key
            paramsObj.searchValue = e.target.value
            paramsObj.sortingObj = sortingObj
            const data = await taskSearchbyField(paramsObj)
            resTask(data)
        } else {
            loaderSetting(true)
            const data = await getTasklist(
                paramsObj.pageSize,
                paramsObj.pageNumber,
                sortingObj,
                productionDate,
                prodEndDate,
            )
            setAverageTat(data.averageTat / 1000 / 60)

            resTask(data)
        }
    }
    const auditorList = async () => {
        const auditors: AuditorType = await getAuditors()
        if (auditors?.status) {
            setAuditors(auditors?.users)
        }
    }
    const taskListAPICall = async (name: any, page: number) => {
        paramsObj.pageSize = pagination.count
        paramsObj.pageNumber = page
        if (
            (name.length > 0 && name !== '') ||
            filterFlag?.status?.length > 0 ||
            filterFlag?.auditor?.length > 0
        ) {
            paramsObj.searchValue = name
            paramsObj.status = filterFlag.status
            paramsObj.auditor = filterFlag?.auditor?.map((item: any) => {
                return item.id
            })
            const data = await taskSearch(paramsObj)
            resTask(data)
        } else if (filteredValue?.length > 0 && filteredValue !== '') {
            paramsObj.searchKey = settingsOptions?.find(
                (item: any) => item.label === filterdHeader,
            )?.key
            paramsObj.searchValue = filteredValue
            paramsObj.sortingObj = sortingObj
            const data = await taskSearchbyField(paramsObj)
            resTask(data)
        } else {
            loaderSetting(true)
            const data = await getTasklist(
                pagination.count,
                page,
                sortingObj,
                productionDate,
                prodEndDate,
                { displayStatus: filterButton },
            )
            setAverageTat(data.averageTat / 1000 / 60)

            resTask(data)
            setStatusCount(data?.statusCounts)
        }
    }
    const resTask = (data: any) => {
        loaderSetting(false)
        if (data?.status) {
            setTaskList({ payload: data?.payload, type: 'add' })

            setPagination({
                ...pagination,
                currentPage: data?.currentPage,
                totalPages: data?.totalPages,
                totalRecords: data?.totalRecords,
            })
        } else {
            setTaskList({ payload: [], type: 'add' })
            setPagination(paginationParams)
            snackbarSetting(true, data?.message, 'error')
        }
    }

    const handleRequestSort = async (property: string) => {
        let updatedSortingObj: SortingObj = { ...sortingObj }
        if (property in updatedSortingObj) {
            updatedSortingObj[property] *= -1
        } else {
            updatedSortingObj = { [property]: -1 }
        }
        setSortingObj(updatedSortingObj)
    }

    const openDrawer = (key: any, data: any, addendumIndex?: any) => {
        setEnableModel(key)

        if (data) {
            if (addendumIndex >= 0) {
                setAddendumIndex(addendumIndex)
                // gatherEncounterData(data.encounterId, addendumIndex)
                setTask(data)
                gatherEncounterData(
                    data.encounterId,
                    data.addendumEncounters[addendumIndex].id,
                )
                setATask(data.addendumEncounters[addendumIndex])
            } else {
                gatherEncounterData(data.encounterId)
                setTask(data)
            }
        }
    }
    const openModel = (data: any) => {
        dispatch(audioModal_action(true))
        dispatch(file_preview_open(true))
        if (data) {
            dispatch(
                audioPlayer_action({
                    appointmentId: data?.appointmentId,
                    scheduleId: data?.scheduleId,
                    encounterId: data?.encounterId,
                }),
            )
            setTask(data)
        }
    }
    const modalClose = () => {
        dispatch(audioModal_action(false))
        dispatch(file_preview_open(false))
    }
    const openTranscriptModel = (data: any) => {
        setTranscriptVisible(true)
        dispatch(file_preview_open(true))
        setModalVisible(false)
        if (data) {
            setTask(data)
        }
    }
    const openFillablePdfModel = (data: any) => {
        setFillablePdfmodal(true)
        dispatch(file_preview_open(true))
        if (data) {
            setTask(data)
        }
    }
    const openDocuemt3PdfModel = (data: any) => {
        setDocument3PdfModel(true)
        dispatch(file_preview_open(true))
        if (data) {
            setTask(data)
        }
    }
    const modalTranscriptClose = () => {
        setTranscriptVisible(false)
        setFillablePdfmodal(false)
        setDocument3PdfModel(false)
        dispatch(file_preview_open(false))
    }
    const updatedTaskList = async (data: any) => {
        if (data) {
            setTask(data)
            await barchart()
            await bubbleChart()
            if (enableModel !== 4 && enableModel !== null) {
                if (
                    filterFlag?.status?.length > 0 ||
                    filterFlag?.auditor?.length > 0
                ) {
                    if (
                        filterFlag?.status?.find(
                            (item: any) => item === data.status,
                        ) &&
                        filterFlag?.auditor?.find(
                            (item: any) => item.id === data.auditorId,
                        )
                    ) {
                        setTaskList({ payload: data, type: 'update' })
                    } else {
                        setTaskList({ payload: data, type: 'remove' })
                        await taskListAPICall(name, 1)
                    }
                } else {
                    setTaskList({ payload: data, type: 'update' })
                }
                if (enableModel === 6) {
                    setEnableModel(7)
                    loaderSetting(false)
                }
            } else {
                setopenFilter(true)
                setName('')
                setFilterdValue('')
                setFilterFlag(filterObj)
                await taskListAPICall('', 1)
            }
        }
    }
    const closeTaskDrawer = () => {
        setEnableModel(null)
        setAddendumIndex(null)
    }
    const searchByName = async (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
        setName(e.target.value)
        await taskListAPICall(e.target.value, 1)
    }

    const chartFilter = (value: any, key: string, dispatch: boolean) => {
        setopenFilter(true)
        setFilterdValue('')
        setPagination({
            ...pagination,
            count: pagination.count,
            currentPage: 1,
        })
        if (key === 'auditor') {
            if (dispatch) {
                setFilterFlag({
                    ...filterFlag,
                    auditor: _.unionBy([...filterFlag.auditor, value]),
                    showAuditor: true,
                })
            } else {
                setFilterFlag({
                    ...filterFlag,
                    showAuditor:
                        _.without(filterFlag.auditor, value).length > 0
                            ? true
                            : false,
                    auditor: _.without(filterFlag.auditor, value),
                })
            }
        } else {
            if (dispatch) {
                setFilterFlag({
                    ...filterFlag,
                    status: _.unionBy([...filterFlag.status, value.label]),
                    showStatus: true,
                })
            } else {
                setFilterFlag({
                    ...filterFlag,
                    showStatus:
                        _.without(filterFlag.status, value).length > 0
                            ? true
                            : false,
                    status: _.without(filterFlag.status, value),
                })
            }
        }
    }
    return {
        averageTat,
        sortingObj,
        fillablePdfmodal,
        openFillablePdfModel,
        modalClose,
        modalTranscriptClose,
        transcriptmodal,
        modalVisible,
        bubblechartData,
        barchartDataCount,
        barchartData,
        chartFilter,
        filterMenuChange,
        filterOnChange,
        filterSearchByField,
        searchByName,
        auditors,
        updatedTaskList,
        closeTaskDrawer,
        task,
        aTask,
        addendumIndex,
        enableModel,
        name,
        openFilter,
        filterFlag,
        pagination,
        setPagination,
        taskList,
        filteredValue,
        filterdHeader,
        handleRequestSort,
        openDrawer,
        openTranscriptModel,
        openModel,
        openDocuemt3PdfModel,
        document3PdfModel,
        encounterData,
        addendumEncounterData,
        statusCount,
        loaderBool,
        gatherEncounterData,
        filterButton,
        setFilterButton,
        setRefresh,
        refresh,
    }
}
