import { PRODUCTION_DATE, PRODUCTION_END_DATE } from '../../actions/actionType'
import dayjs from 'dayjs'
import { addDays, startOfWeek, endOfWeek } from 'date-fns'

const date = startOfWeek(new Date(), { weekStartsOn: 0 })
const endDate = endOfWeek(new Date(), { weekStartsOn: 0 })
// const date = dayjs()
date.setHours(0, 0, 0, 0)
endDate.setHours(23, 59, 59, 59)
const dateString = String(date)
const endDateString = String(endDate)

const initialState = { day: dateString, endDay: endDateString }
// const initialState = { day: date }

export default function productionDateReducer(
    state = initialState,
    action: any,
) {
    switch (action.type) {
        case PRODUCTION_DATE:
            return {
                ...state,
                day: action.payload,
            }
        case PRODUCTION_END_DATE:
            return {
                ...state,
                endDay: action.payload,
            }
        default:
            return state
    }
}
