import React, { useEffect, useState } from 'react'
import { listAuditorsAPI, listProvidersAPI } from '../api/managerAPIService'
import { auditorListAPI } from '../api/auditorAPIService'

export function useTATMeterService(props: any) {
    interface auditorListTypes {
        // "_id": "66a9710a708ffaf73ed52005",
        mainSpeciality?: string
        providers?: any
        firstName?: string
        lastName?: string
        lastLoginTime: Date
        totalJobs: number
        averageTAT: any
        workHours: any
    }
    interface providerListTypes {
        providerName?: string
        contractClient?: string
        cityState?: string
        siteOfService?: string
        specialty?: string
        assignedAuditors?: Array<string>
        numRecordingsSubmitted?: number
        averageTAT?: number
    }
    const [auditorList, setAuditorList] = useState<Array<auditorListTypes>>([])
    const [providerList, setProviderList] = useState<Array<providerListTypes>>(
        [],
    )
    useEffect(() => {
        async function initiate() {
            await getAuditors()
            await getProviders()
        }
        initiate()
    }, [props.providerSwitch])

    const getProviders = async () => {
        try {
            const resp = await listProvidersAPI()
            setProviderList(resp.providers)
            console.log('resp1', resp)
        } catch (err) {
            console.log('error getting providers', err)
        }
    }
    const getAuditors = async () => {
        try {
            const resp = await listAuditorsAPI()
            setAuditorList(resp)
            console.log('resp2', resp)
        } catch (err) {
            console.log('error getting auditor list', err)
        }
    }
    return { auditorList, providerList }
}
