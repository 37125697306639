import React from 'react'
import { SocketContext } from '../../Components/Sockets/Socket'
import { UnreadCountContext } from '../../Components/Unreadcount/UnreadMessageCount'

export interface ViewTaskProps {
    task?: {
        taskName?: string
        location?: string
        patientAccount?: string
        orgTid?: string
        speciality?: string
        mrn?: string
        auditorName?: string
        encounterCollection?: string
        comment?: any
        patientdob?: any
        auditorId?: string
        providerId?: string
        patientName?: string
        appointmentId?: string
        status?: string
        patientGender?: string
        auditor?: any
        provider?: any
        startDate?: any
        endDate?: any
        encounterId?: any
        createdBy?: string
        updatedBy?: string
        type?: string
        id?: string
        encounter?: any
        providerName?: string
        displayStatus?: string
    } | null
    aTask?: {
        taskName?: string
        location?: string
        patientAccount?: string
        orgTid?: string
        speciality?: string
        mrn?: string
        auditorName?: string
        encounterCollection?: string
        comment?: any
        patientdob?: any
        auditorId?: string
        providerId?: string
        patientName?: string
        appointmentId?: string
        status?: string
        patientGender?: string
        auditor?: any
        provider?: any
        startDate?: any
        endDate?: any
        encounterId?: any
        createdBy?: string
        updatedBy?: string
        type?: string
        id?: string
        encounter?: any
        providerName?: string
    } | null
    aIndex?: any | null
    updatedTaskList?: any
    menuHandleCloseClick?: () => void
    auditorList?: any
    handleClosed?: () => void
    setStates?: any
    openEdit?: any
    openEditAuditor?: any
    showEditbtn?: boolean
    encounterData?: any
    addendumData?: any
    setRefreshPage?: any
    refreshPage?: boolean
}

export function useViewtask(props: ViewTaskProps) {
    const [states, setStates] = React.useState<any>(null)
    const [tableValue, setTableValue] = React.useState(0)
    const [chatnavigation, setFlagShowMsg] = React.useState<any>(null)
    const { unreadCount, setUnreadCount } = React.useContext(UnreadCountContext)
    const { socket } = React.useContext(SocketContext)
    React.useEffect(() => {
        // if (props?.aTask) {
        //     console.log('states-view', props.aTask)
        //     setStates(props.task)
        // } else {
        //     setStates(props?.task)
        // }
        setStates(props?.task)
    }, [])

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTableValue(newValue)
    }

    const [trackStatusFlag, setTrackStatusFlag] = React.useState(false)
    const [openEditTaskDrawer, setOpenEditTaskrawer] = React.useState(false)

    const editTaskDialog = () => {
        setOpenEditTaskrawer(!openEditTaskDrawer)
    }

    const openTrackStatus = () => {
        setTrackStatusFlag(!trackStatusFlag)
    }

    // React.useEffect(() => {
    //     const bodyParams = {
    //         taskId: props?.task?.id,
    //     };
    //     async function updateUnreadCount() {
    //         const response = await totalunreadcount(bodyParams);
    //         setUnreadCount(response.totalUnreadThreadCount);
    //     }
    //     updateUnreadCount();
    //     const receiverSocket = () => {
    //         updateUnreadCount();
    //     };
    //     socket?.on('receive_message', receiverSocket);
    //     return () => {
    //         socket?.off('receive_message', receiverSocket);
    //     };

    // }, [props?.task?.id, socket, unreadCount, chatnavigation]);

    return {
        unreadCount,
        setTableValue,
        chatnavigation,
        setFlagShowMsg,
        handleTabChange,
        tableValue,
        states,
        setStates,
        trackStatusFlag,
        openEditTaskDrawer,
        openTrackStatus,
        editTaskDialog,
    }
}
