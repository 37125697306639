import React, { useEffect, useState } from 'react'
import createPlotlyComponent from 'react-plotly.js/factory'
import * as Plotly from 'plotly.js-dist-min'
import styles from './JobAuditorGraph.module.css'
import { range } from 'pdf-lib'

interface DataType {
    x: any
    y: any
    type: any
    marker: { color: string[] }
}
function JobAuditorGraph({ auditorList }: any) {
    const [data, setData] = useState<any>([])
    const editedAuditorList: any[] = []
    const randomNumber: any[] = []

    const generateHex = () => {
        const hexCharacters = '0123456789abcdef'
        let hexCode = '#'
        for (let i = 0; i < 6; i++) {
            hexCode +=
                hexCharacters[Math.floor(Math.random() * hexCharacters.length)]
        }
        return hexCode
    }
    const hexArray: string[] = []
    useEffect(() => {
        const plotlyData = [
            {
                x: auditorList.map((item: any) => item.auditorName),
                y: auditorList.map((item: any) => item.assignedTask),
                type: 'bar',
                marker: {
                    color: [
                        'rgb(0,132,206)',
                        // 'rgb(251,206,20)',
                        // 'rgb(206,74,0)',
                        // 'rgb(206,110,10)',
                        // 'rgb(206,174,20)',
                        // 'rgb(206,74,130)',
                    ],
                },
            },
        ]
        plotlyData[0].x.map(() => {
            hexArray.push(generateHex())
            // plotlyData.marker.color.push(generateHex())
        })
        plotlyData[0].marker.color = hexArray
        //  = ['#'+''.join([random.choice('0123456789ABCDEF') for j in range(6)]) for i in range(plotlyData.x.length)]
        setData(plotlyData)
    }, [auditorList])

    auditorList?.map((auditor: any) => {
        editedAuditorList.push(auditor.auditorName)
        randomNumber.push(Math.floor(Math.random() * 50))
    })
    // console.log('editedauditor', editedAuditorList)
    const Plot = createPlotlyComponent(Plotly)
    const plotdata = [
        {
            x: editedAuditorList,
            y: randomNumber,
            // ylabel: 'score',
            type: 'bar',

            marker: {
                color: [
                    'rgb(0,132,206)',
                    'rgb(251,206,20)',
                    'rgb(206,74,0)',
                    'rgb(206,110,10)',
                    'rgb(206,174,20)',
                    'rgb(206,74,130)',
                ],
            },
        },
    ]
    // console.log('data', data, plotdata)

    return (
        <div>
            <Plot
                className={styles.JobAuditorGraphContainer}
                data={data}
                layout={{
                    font: { size: 16 },
                    title: 'Number of Jobs by Auditor',
                    xaxis: {
                        // title: 'Status',
                        // title: {
                        //     text: 'Status',
                        //     font: { weight: 20, size: 25 },
                        //     standoff: 40,
                        // },
                        // font: { size: 1 },
                        ticklabelposition: 'inside',
                        ticklabeloverflow: 'allow',
                        tickangle: -90,
                        ticklabelstandoff: 20,
                    },
                    yaxis: { title: 'Number of Jobs', range: [0, 50] },
                }}
                config={{ responsive: true }}
                // onClick={() => console.log('onclick')}
            />
        </div>
    )
}

export default JobAuditorGraph
