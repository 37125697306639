import {
    CONFIG,
    PROFILE,
    USER,
    USERRETOKEN,
    USERSETTING,
    USERTOKEN,
    PATIENT_NAME,
    PROVIDER_NAME,
    ENCOUNTER_DATE,
} from '../../actions/actionType'

const initialState = {
    config: { name: 'ClaimsQ' },
    user: null,
    userSetting: null,
    refreshToken: null,
    userToken: null,
    profile: null,
    patient: '',
    provider: '',
    encounterDate: '',
}

export default function userReducer(state = initialState, action: any) {
    switch (action.type) {
        case CONFIG: {
            return {
                ...state,
                config: action.payload,
            }
        }
        case USER: {
            return {
                ...state,
                user: action.payload,
            }
        }
        case USERSETTING: {
            return {
                ...state,
                userSetting: action.payload,
            }
        }
        case USERTOKEN: {
            return {
                ...state,
                userToken: action.payload,
            }
        }
        case USERRETOKEN: {
            return {
                ...state,
                refreshToken: action.payload,
            }
        }
        case PROFILE: {
            return {
                ...state,
                profile: action.payload,
            }
        }
        case PATIENT_NAME: {
            return {
                ...state,
                patient: action.payload,
            }
        }
        case PROVIDER_NAME: {
            return {
                ...state,
                provider: action.payload,
            }
        }
        case ENCOUNTER_DATE: {
            return {
                ...state,
                encounterDate: action.payload,
            }
        }
        default:
            return state
    }
}
