import {
    USER,
    USERSETTING,
    USERTOKEN,
    USERRETOKEN,
    PROFILE,
    CONFIG,
    PATIENT_NAME,
    PROVIDER_NAME,
    ENCOUNTER_DATE,
} from './actionType'

const userConfig = (data: any) => ({ type: CONFIG, payload: data })
const userAction = (data: any) => ({ type: USER, payload: data })
const userSettingAction = (data: any) => ({ type: USERSETTING, payload: data })
const userTokenAction = (data: any) => ({ type: USERTOKEN, payload: data })
const userReTokenAction = (data: any) => ({ type: USERRETOKEN, payload: data })
const userProfileAction = (data: any) => ({ type: PROFILE, payload: data })
const userPatientNameAction = (data: any) => ({
    type: PATIENT_NAME,
    payload: data,
})
const userProviderNameAction = (data: any) => ({
    type: PROVIDER_NAME,
    payload: data,
})
const userEncounterDateAction = (data: any) => ({
    type: ENCOUNTER_DATE,
    payload: data,
})

export {
    userConfig,
    userAction,
    userSettingAction,
    userTokenAction,
    userReTokenAction,
    userProfileAction,
    userPatientNameAction,
    userProviderNameAction,
    userEncounterDateAction,
}
