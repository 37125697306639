import React from 'react'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Tooltip from '@mui/material/Tooltip'
import AdbIcon from '@mui/icons-material/Adb'
import typoStyles from '../../Styles/component/Typography.module.css'
import Notification from '../../Components/Notification/Notification'
import NotificationDrawer from '../../Components/NotificationDrawer'
import Logo from '../../Assests/Icons/MicrosoftTeams-image (4).png'
import Badge from '@mui/material/Badge'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { roleName } from '../../Services/utils/constant'
import { useDispatch, useSelector } from 'react-redux'
import { DatePickerToolbar } from '@mui/x-date-pickers'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { unstable_useForkRef as useForkRef } from '@mui/utils'
import dayjs, { Dayjs } from 'dayjs'
import { Button, Popover } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {
    productionDateEndSetting,
    productionDateSetting,
} from '../../Services/actions/productionDateAction'
import { useLocation } from 'react-router-dom'
import CodeQLogo from '../../Assests/CodeQLogo.png'
import LogoView from '../User/SignIn/LogoView'
import enUS from 'date-fns/locale/en-US'
import { DateRangePicker, DefinedRange } from 'react-date-range'
import EventIcon from '@mui/icons-material/Event'
import moment from 'moment'
import { addDays, startOfWeek, endOfWeek } from 'date-fns'

interface HeaderProps {
    notificationCount?: any
}
interface RangeSelection {
    startDate?: Date
    endDate?: Date
    key: string
}
const Header = ({ notificationCount }: HeaderProps) => {
    const dispatch = useDispatch()

    const userRole = useSelector(
        (state: any) => state.persistReducers?.userReducer?.user?.userRole,
    )
    const userName = useSelector(
        (state: any) => state.persistReducers?.userReducer?.user,
    )
    const productionDate = useSelector(
        (state: any) => state.nonPersistReducers?.productionDateReducer.day,
    )
    const userConfig = useSelector(
        (state: any) => state.persistReducers?.userReducer?.config?.name,
    )
    const patientName = useSelector(
        (state: any) => state.persistReducers?.userReducer?.patient,
    )
    const providerName = useSelector(
        (state: any) => state.persistReducers?.userReducer?.provider,
    )
    const encounterDate = useSelector(
        (state: any) => state.persistReducers?.userReducer?.encounterDate,
    )

    const location = useLocation()
    const addDays = (days: number) => {
        setSelectionRange((prevState: any) => {
            const tmpState = prevState
            if (
                moment(selectionRange.startDate).format('MM/DD/YYYY') !=
                moment(selectionRange.endDate).format('MM/DD/YYYY')
            ) {
                tmpState.endDate.setDate(tmpState.startDate.getDate())
            }
            tmpState.startDate.setDate(tmpState.startDate.getDate() + days)
            tmpState.startDate.setHours(0, 0, 0, 0)
            tmpState.endDate.setDate(tmpState.endDate.getDate() + days)
            tmpState.endDate.setHours(23, 59, 59, 59)
            return tmpState
        })
    }

    const [, setAnchorElUser] = React.useState<null | HTMLElement>(null)
    const [openDrawer, setopenDrawer] = React.useState(false)
    const [hideAll, setHideAll] = React.useState(
        location?.pathname == '/reports' ? true : false,
    )
    const [refresh, setRefresh] = React.useState(false)
    const [dayJSProductionDate, setProductionDate] = React.useState<
        Dayjs | any
    >(dayjs(productionDate))
    const [hideButtons, setHideButtons] = React.useState(
        location?.pathname == '/coding' ||
            location?.pathname == '/usermanagement'
            ? true
            : false,
    )
    const [selectionRange, setSelectionRange] = React.useState<RangeSelection>({
        startDate: startOfWeek(new Date(), { weekStartsOn: 0 }),
        endDate: endOfWeek(new Date(), { weekStartsOn: 0 }),
        key: 'selection',
    })
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null,
    )

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        setopenDrawer(true)
        setAnchorElUser(event.currentTarget)
    }

    const closedrawer = () => {
        setopenDrawer(false)
        setAnchorElUser(null)
    }
    // console.log('date', productionDate)
    // console.log('path', location.pathname, hideButtons)
    // console.log('hideall', hideAll, location.pathname)
    React.useEffect(() => {
        if (
            // location?.pathname == '/coding' ||
            location?.pathname == '/usermanagement'
        ) {
            setHideButtons(true)
            setHideAll(false)
        } else if (location?.pathname == '/reports') {
            setHideAll(true)
        } else if (location?.pathname == '/coding') {
            setHideButtons(true)
        } else {
            setHideButtons(false)
            setHideAll(false)
        }
    }, [location.pathname])

    interface BrowserFieldProps
        extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
        label?: React.ReactNode
        inputRef?: React.Ref<any>
        InputProps?: {
            ref?: React.Ref<any>
            endAdornment?: React.ReactNode
            startAdornment?: React.ReactNode
        }
        error?: boolean
        focused?: boolean
        ownerState?: any
        sx?: any
        enableAccessibleFieldDOMStructure: boolean
    }

    type BrowserFieldComponent = ((
        props: BrowserFieldProps & React.RefAttributes<HTMLDivElement>,
    ) => React.JSX.Element) & { propTypes?: any }

    const BrowserField = React.forwardRef(
        (props: BrowserFieldProps, ref: React.Ref<HTMLDivElement>) => {
            const {
                // Should be ignored
                // enableAccessibleFieldDOMStructure,

                disabled,
                id,
                label,
                inputRef,
                InputProps: {
                    ref: containerRef,
                    startAdornment,
                    endAdornment,
                } = {},
                // extracting `error`, 'focused', and `ownerState` as `input` does not support those props
                error,
                focused,
                ownerState,
                sx,
                ...other
            } = props

            const handleRef = useForkRef(containerRef, ref)
            // console.log('label', label)
            return (
                <Box
                    sx={{
                        ...(sx || {}),
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    id={id}
                    ref={handleRef}
                >
                    {startAdornment}
                    {/* <input disabled={disabled} ref={inputRef} {...other} /> */}
                    {endAdornment}
                </Box>
            )
        },
    )

    //  as BrowserFieldComponent

    const BrowserDatePicker = React.forwardRef(
        (props: DatePickerProps<Dayjs>, ref: React.Ref<HTMLDivElement>) => {
            return (
                <DatePicker
                    ref={ref}
                    {...props}
                    slots={{ ...props.slots, field: BrowserField }}
                />
            )
        },
    )
    BrowserDatePicker.displayName = 'BrowserDatePicker'
    BrowserField.displayName = 'BrowserField'
    const handleSelect = (ranges: any) => {
        setSelectionRange(ranges.selection)
        // console.log('select', ranges.selection)
        ranges.selection.endDate.setHours(23, 59, 59, 59)
        dispatch(productionDateSetting(String(ranges.selection.startDate)))
        dispatch(productionDateEndSetting(String(ranges.selection.endDate)))
    }

    // console.log('user', userRole)
    return hideAll ? (
        <div></div>
    ) : (
        <Container
            style={{ minHeight: '40px' }}
            maxWidth={false}
            sx={{
                '@media screen and (min-width:2000px)': {
                    marginLeft: '250px',
                },
                backgroundColor:
                    userRole == 'auditor'
                        ? '#a6d2ff'
                        : userRole == 'auditoradmin'
                        ? '#fc8e88'
                        : '#fcc995',
                paddingLeft: 0,
                marginLeft: 0,
                padding: '2px',
                height: '40px',
                alignItems: 'center',
                // minHeight: '30px',
            }}
        >
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <DateRangePicker
                    locale={enUS}
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                />
            </Popover>
            <div
                style={{
                    minHeight: '40px',
                    display: 'flex',
                    gap: '20px',
                    alignItems: 'center',
                }}
            >
                <LogoView
                    from="header"
                    userConfig={userConfig}
                    path={location.pathname}
                />
                {/* <img
                    alt="CodeQ Logo"
                    src={CodeQLogo}
                    style={{ height: 30, padding: '0px 0px 0px 30px' }}
                /> */}

                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexGrow: 0,
                            gap: '10px',
                            color: 'black',
                        }}
                    >
                        <Typography
                            variant="h6"
                            className={typoStyles.typoRobotoBoldprimary1214}
                            noWrap
                        >
                            Production Date:
                        </Typography>
                        {/* <Button
                            hidden={hideButtons}
                            onClick={() => {
                                addDays(-1)
                                // dispatch(notificationCountSetting(1))
                            }}
                        >
                            <ArrowBackIosNewIcon />
                        </Button> */}
                        <Typography
                            variant="h6"
                            noWrap
                            className={typoStyles.typoRobotoNormalprimary121412}
                        >
                            {moment(selectionRange.startDate).format(
                                'MM/DD/YYYY',
                            ) ==
                            moment(selectionRange.endDate).format('MM/DD/YYYY')
                                ? moment(selectionRange.startDate).format(
                                      'MM/DD/YYYY',
                                  )
                                : moment(selectionRange.startDate).format(
                                      'MM/DD/YYYY',
                                  ) +
                                  '-' +
                                  moment(selectionRange.endDate).format(
                                      'MM/DD/YYYY',
                                  )}
                            {/* // {dayJSProductionDate?.format('MM/DD/YYYY')} */}
                            {/* {dayjs(productionDate)} */}
                            {/* {productionDate?.format('MM/DD/YYYY')} */}
                        </Typography>
                        {/* <Button
                            hidden={hideButtons}
                            onClick={() => {
                                addDays(1)
                                // dispatch(notificationCountSetting(1))
                            }}
                        >
                            <ArrowForwardIosIcon />
                        </Button> */}
                        <div
                            className="Picker"
                            hidden={hideButtons}
                            style={{ paddingRight: 20 }}
                        >
                            <IconButton
                                aria-describedby={id}
                                // variant="contained"
                                onClick={handleClick}
                            >
                                <EventIcon />
                            </IconButton>

                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <BrowserDatePicker
                                    // label={value == null ? null : value.format('MM/DD/YYYY')}
                                    // value={productionDate}
                                    value={dayJSProductionDate}
                                    onChange={(newValue: Dayjs | any) => {
                                        // setProductionDate(newValue)
                                        addDays(newValue, 0)
                                    }}
                                    slotProps={{
                                        field: { clearable: true },
                                    }}
                                />
                                {/* {MuiDatePicker} 
                            </LocalizationProvider> */}
                        </div>
                    </Box>
                </Box>

                <Box>
                    <Box sx={{ flexGrow: 0, color: 'black' }}>
                        <Typography
                            variant="h6"
                            noWrap
                            className={typoStyles.typoRobotoBoldprimary1214}
                        >
                            {userName?.firstName} {userName?.lastName}
                        </Typography>
                    </Box>
                </Box>
                {patientName != '' ? (
                    <Box>
                        <Box sx={{ flexGrow: 0, color: 'black' }}>
                            <Typography
                                variant="h6"
                                noWrap
                                className={
                                    typoStyles.typoRobotoNormalprimary121412
                                }
                            >
                                <b>Patient: </b>
                                {patientName}
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <></>
                )}
                {providerName != '' ? (
                    <Box>
                        <Box sx={{ flexGrow: 0, color: 'black' }}>
                            <Typography
                                variant="h6"
                                noWrap
                                className={
                                    typoStyles.typoRobotoNormalprimary121412
                                }
                            >
                                <b>Provider: </b>
                                {providerName}
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <></>
                )}
                {encounterDate != '' ? (
                    <Box>
                        <Box sx={{ flexGrow: 0, color: 'black' }}>
                            <Typography
                                variant="h6"
                                noWrap
                                className={
                                    typoStyles.typoRobotoNormalprimary121412
                                }
                            >
                                <b>Encounter Date: </b>
                                {encounterDate}
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <></>
                )}
                <Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open Notification">
                            <Badge
                                badgeContent={notificationCount}
                                color="error"
                            >
                                <IconButton
                                    onClick={handleOpenUserMenu}
                                    sx={{ p: 0 }}
                                >
                                    <NotificationsNoneIcon />
                                </IconButton>
                            </Badge>
                        </Tooltip>
                    </Box>
                </Box>
            </div>
            {openDrawer && (
                <NotificationDrawer openDrawer={true}>
                    <Notification closedrawer={closedrawer} />
                </NotificationDrawer>
            )}
        </Container>
    )
}
export default Header
